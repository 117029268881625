<template>
	<modal class="NoxModalBotOrders" name="NoxModalBotOrders" modal-width="760" :width="760" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_open_loading" v-if="noxIsOpenLoading"><div class="nox_loading"></div></div>
			<div class="nox_modal_open" v-else>
				<div class="nox_modal_head">
					<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					<div class="nox_modal_title">
						<span v-if="noxType == 'getBotOrders'" v-html="$store.getters.getLanguageText('5.22', 0)"></span>
					</div>
				</div>
				<div class="nox_modal_body">
					<div class="nox_modal_info">
						<div v-if="noxType == 'getBotOrders'">
							<div class="nox_modal_info_bot_orders" v-if="noxBotOrdersData.length">
								<div class="nox_modal_info_bot_orders_csv">
									<button type="button" class="nox_button big blue" @click="$modal.show('NoxModalRecaptcha', { type: 'getBotOrdersCSV', index: noxIndex, id: noxId })"><font-awesome-icon :icon="['fas', 'download']" /><span v-html="$store.getters.getLanguageText('1.1', 170)"></span></button>
								</div>
								<div class="nox_modal_info_bot_orders_data">
									<table class="nox_table">
										<thead>
											<tr>
												<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 41)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 61)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 62)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 63)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 64)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 65)"></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(value, index) in noxBotOrdersData" :key="index">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ value.tid }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 41) + ':'" :class="['green', 'red', '', '', '', '', 'blue'][value.type]">{{ ['buy', 'sell', '', '', '', '', 'deposit'][value.type] }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 61) + ':'" class="uppercase">{{ value.symb }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 62) + ':'">{{ (value.type == 6 ? '' : value.lots) }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 63) + ':'" :class="[(value.prof >= 0 ? 'green' : 'red')]">{{ ((value.prof >= 0 ? '+' : '−') + '&#160;' + Math.abs(value.prof).toFixed(2)) }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 64) + ':'">{{ new Date(value.t_o * 1000).toLocaleString($store.state.noxLanguage) }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 65) + ':'">{{ new Date(value.t_c * 1000).toLocaleString($store.state.noxLanguage) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div v-else v-html="$store.getters.getLanguageText('5.22', 1)"></div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="button" class="nox_button common" @click="axios('getBotOrders')" v-if="noxType == 'getBotOrders' && noxBotOrdersDataLimitOffset" v-html="$store.getters.getLanguageText('1.1', 20)"></button>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxBotOrdersData: [],
			noxBotOrdersDataTableOffset: 0,
			noxBotOrdersDataLimitOffset: 0,
			noxBotOrdersDataLimitCount: 30,
			noxIsOpenLoading: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxBotOrdersData = [];
				this.noxBotOrdersDataTableOffset = 0;
				this.noxBotOrdersDataLimitOffset = 0;
				this.noxBotOrdersDataLimitCount = 30;
				this.noxIsOpenLoading = false;
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'getBotOrders') { this.axios(this.noxType); }
			},
			resetAlerts: function() {
				this.noxAlert = '';
			},
			getError1: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 8); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError2: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 3); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 4); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 5); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 6); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 7); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 8); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 9); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 10); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 11); }
				else if (i == 10) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 12); }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'getBotOrders') {
						_this.noxIsLoading = true;
						_this.noxIsOpenLoading = !_this.noxBotOrdersDataLimitOffset ? true : false;
						config.url = '/v2/account/bots/orders';
						config.params = { id: _this.noxId, tableOffset: _this.noxBotOrdersDataTableOffset, limitOffset: _this.noxBotOrdersDataLimitOffset, limitCount: _this.noxBotOrdersDataLimitCount };
					}

					if (!_this.noxIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'getBotOrders') {
									if (data.data.data.length) {
										for (var i in data.data.data) {
											_this.noxBotOrdersData.push(data.data.data[i]);
										}
									}
									_this.noxBotOrdersDataTableOffset = data.data.info.max_id;
									_this.noxBotOrdersDataLimitOffset = data.data.info.count > _this.noxBotOrdersDataLimitOffset + _this.noxBotOrdersDataLimitCount ? _this.noxBotOrdersDataLimitOffset + _this.noxBotOrdersDataLimitCount : 0; return false;
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (type == 'getBotOrders') {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError1(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError1(2); }
										else if (data.response.data.error == 'LIMIT_OFFSET_IS_EMPTY') { _this.noxAlert = _this.getError2(1); }
										else if (data.response.data.error == 'LIMIT_OFFSET_NOT_VALID') { _this.noxAlert = _this.getError2(2); }
										else if (data.response.data.error == 'LIMIT_COUNT_IS_EMPTY') { _this.noxAlert = _this.getError2(3); }
										else if (data.response.data.error == 'LIMIT_COUNT_NOT_VALID') { _this.noxAlert = _this.getError2(4); }
										else if (data.response.data.error == 'SORT_FIELD_IS_EMPTY') { _this.noxAlert = _this.getError2(5); }
										else if (data.response.data.error == 'SORT_FIELD_NOT_VALID') { _this.noxAlert = _this.getError2(6); }
										else if (data.response.data.error == 'SORT_ORDER_IS_EMPTY') { _this.noxAlert = _this.getError2(7); }
										else if (data.response.data.error == 'SORT_ORDER_NOT_VALID') { _this.noxAlert = _this.getError2(8); }
										else if (data.response.data.error == 'TABLE_OFFSET_IS_EMPTY') { _this.noxAlert = _this.getError2(9); }
										else if (data.response.data.error == 'TABLE_OFFSET_NOT_VALID') { _this.noxAlert = _this.getError2(10); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (type == 'getBotOrders') {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError1(3); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError1(0);
							}
						}).finally(function() {
							_this.noxIsOpenLoading = false;
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalBotOrders');
			}
		}
	}
</script>
